/* ========================================
 * Gallery
 * ======================================== */

import $ from 'jquery';
import 'slick-carousel';
import 'lightgallery';

class Gallery {
  constructor() {
    this.galleryClass = '.js-SliderGallery';
    this.$gallery = $(this.galleryClass);

    this.itemsClass = '.js-SliderGallery-items';
    this.$items = $(this.itemsClass, this.$gallery);

    this.thumbnailsClass = '.js-SliderGallery-thumbnails';
    this.$thumbnails = $(this.thumbnailsClass, this.$gallery);
  }

  init() {
    this.initGallery();
  }

  initGallery() {
    this.$items.slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      asNavFor: this.thumbnailsClass,
    });

    this.$items.lightGallery({
      selector: '.SliderGallery-item',
      counter: false,
    });

    this.$thumbnails.slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      dots: true,
      arrows: false,
      focusOnSelect: true,
      asNavFor: this.itemsClass,
    });
  }
}

export default Gallery;
