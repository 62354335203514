/* ========================================
 * Accordion
 * ======================================== */

import $ from 'jquery';
import classToggle from '../lib/classToggle';

class Accordion {
  constructor() {
    this.$accordion = $('.js-Accordion');
  }

  init() {
    if (this.$accordion.length) {
      this.accordion();
    }
  }

  accordion() {
    classToggle({
      trigger: '.js-Accordion .Accordion-title',
      element: '.js-Accordion .Accordion-item',
      toggleClosest: true,
      unToggleParentSiblings: true,
      unToggleOtherToggles: false,
    });
  }
}

export default Accordion;
