/* ========================================
 * Application
 * ======================================== */

import $ from 'jquery';
window.jQuery = window.$ = $;
import fastClick from 'fastclick';
import 'formdata-polyfill';

import Accordion from './components/Accordion';
import Button from './components/Button';
import Card from './components/Card';
import Hero from './components/Hero';
import FormNewClient from './components/FormNewClient';
import Navbar from './components/Navbar';
import NavbarFilter from './components/NavbarFilter';
import NavTabs from './components/NavTabs';
import OverlayBlock from './components/OverlayBlock';
import RangeSlider from './components/RangeSlider';
import SliderGallery from './components/SliderGallery';
import 'cookieconsent';

$(() => {
  new Accordion().init();
  new Button().init();
  new Card().init();
  new Hero().init();
  new Navbar().init();
  new NavbarFilter().init();
  new NavTabs().init();
  new FormNewClient().init();
  new OverlayBlock().init();
  new RangeSlider().init();
  new SliderGallery().init();
  fastClick(document.body);
});
