/* ========================================
 * Navbar
 * ======================================== */

import $ from 'jquery';
import classToggle from '../lib/classToggle';

class Navbar {
  constructor() {
    this.$navbar = $('.js-Navbar');
  }

  init() {
    if (this.$navbar.length) {
      this.navbar();
    }
  }

  navbar() {
    classToggle({
      trigger: '.js-Navbar .js-NavbarTrigger',
      element: '.js-Navbar',
      toggleClosest: true,
    });

    $('.Navbar-link').each(function () {
      const $this = $(this);

      if ($this.is('a') && ($this.attr('href') === undefined || $this.attr('href') === '#')) {
        $this.addClass('has-noLink');
      }
    });

    classToggle({
      trigger: '.js-Navbar .Navbar-link.has-noLink',
      element: '.js-Navbar .Navbar-item',
      toggleClosest: true,
      unToggleParentSiblings: true,
      unToggleOtherToggles: false,
    });

    classToggle({
      trigger: '.js-Navbar .Navbar-trigger',
      element: '.js-Navbar .Navbar-item',
      toggleClosest: true,
      unToggleParentSiblings: true,
      unToggleOtherToggles: false,
    });
  }
}

export default Navbar;
