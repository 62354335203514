/* =======================================
 * FormNewClient
 * ======================================= */

import { $, $$ } from '../lib/utility';
import Choices from 'choices.js';

class FormNewClient {
  constructor({
    button = '.js-FormNewClientButton',
    billingAddress = '.js-FormNewClientBillingAddress',
    billingAddressInput = '#billing_address_is_same',
    billingTypeInput = '#billing_type',
    billingTypeMail = '.js-FormNewClientBillingTypeMail',
    billingTypeEmail = '.js-FormNewClientBillingTypeEmail',
    billingTypeEinvoice = '.js-FormNewClientBillingTypeEinvoice',
    deliveryAddress = '.js-FormNewClientDeliveryAddress',
    deliveryAddressInput = '#delivery_address_is_same',
    dahlShopRow = '.js-FormNewClientDahlShopRow',
    dahlShopRowButton = '.js-FormNewClientDahlShopRowButton',
    form = '.js-FormNewClient',
    select = '.js-FormNewClientSelect',
  } = {}) {
    this.button = $(button);
    this.billingAddress = $(billingAddress);
    this.billingAddressInput = $(billingAddressInput);
    this.billingTypeInput = $(billingTypeInput);
    this.billingTypeMail = $(billingTypeMail);
    this.billingTypeEmail = $(billingTypeEmail);
    this.billingTypeEinvoice = $(billingTypeEinvoice);
    this.dahlShopRow = $(dahlShopRow);
    this.dahlShopRowButton = $(dahlShopRowButton);
    this.deliveryAddress = $(deliveryAddress);
    this.deliveryAddressInput = $(deliveryAddressInput);
    this.form = $(form);
    this.select = $$(select);
    //this.form.addEventListener('submit', this.handleFormSubmit.bind(this));
  }

  selectInit() {
    if (this.select) {
      this.select.forEach(el => {
        const choice = new Choices(el, {
          noResultsText: 'Ei tuloksia',
          noChoicesText: 'Ei valintoja',
          itemSelectText: 'Paina valitaksesi',
          searchEnabled: false,
        });
      })
    }
  }

  functions() {
    const _this = this;

    this.billingTypeInput &&
    this.billingTypeInput.addEventListener('change', function() {
      _this.billingTypeMail.classList.add('is-hidden');
      _this.billingTypeEmail.classList.add('is-hidden');
      _this.billingTypeEinvoice.classList.add('is-hidden');
      if (this.value == 'mail') {
        _this.billingTypeMail.classList.remove('is-hidden');
      } else if (this.value == 'email') {
        _this.billingTypeEmail.classList.remove('is-hidden');
      }  else if (this.value == 'einvoice') {
        _this.billingTypeEinvoice.classList.remove('is-hidden');
      }
    });

    this.billingAddressInput &&
    this.billingAddressInput.addEventListener('change', function() {
      if (this.checked) {
        _this.billingAddress.classList.add('is-hidden');
      } else {
        _this.billingAddress.classList.remove('is-hidden');
      }
    });

    this.deliveryAddressInput &&
    this.deliveryAddressInput.addEventListener('change', function() {
      if (this.checked) {
        _this.deliveryAddress.classList.add('is-hidden');
      } else {
        _this.deliveryAddress.classList.remove('is-hidden');
      }
    });

    this.dahlShopRowButton &&
    this.dahlShopRowButton.addEventListener('click', function(e) {
      e.preventDefault();
      const prevRow = $('.js-FormNewClientDahlShopRows > div:last-child');
      const newRow = prevRow.cloneNode(true);

      $$('input[name^="tx_dahlregister_regform[dahl_shop_users]"]', newRow).forEach(element => {
        const arrayIndex = /\[\d+\]/;
        const id = parseInt(element.name.match(arrayIndex)[0].replace(/\[|\]/g, '')) + 1;
        element.name = element.name.replace(arrayIndex, `[${id}]`);
      });

      $$('input', newRow).forEach(element => {
        element.value = '';
      });

      prevRow.parentNode.insertBefore(newRow, prevRow.nextSibling);
    });
  }

  handleFormSubmit(event) {
    event.preventDefault();
    const data = new FormData(this.form);
    const url = event.target.action;
    const xhr = new XMLHttpRequest();

    xhr.open('POST', url);
    xhr.setRequestHeader('Content-Type', false);
    xhr.send(data);

    // Just for testing
    const dataObject = {};
    data.forEach((value, key) => { dataObject[key] = value });
    console.log(dataObject);

    this.button.innerHTML = 'Lähetetään...';
    this.button.disabled = true;

    xhr.onreadystatechange = () => {
      console.log(xhr.status, xhr.statusText, xhr.responseText);

      /**
       * Redirect
       */
      if (xhr.status === 200) {
        location.href = '/hei-thanks';
      }
    };
  }

  init() {
    this.selectInit();
    this.functions();
  }
}

export default FormNewClient;
