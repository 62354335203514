/* ========================================
 * Hero
 * ======================================== */

import $ from 'jquery';
import fixObjectFitImage from '../lib/fixObjectFitImage';
import * as ua from '../lib/userAgents';

class Hero {
  constructor() {
    this.$hero = $('.js-Hero');
    this.$slider = $('.Hero-slider', this.$hero);
    this.$figure = $('.Hero-figure', this.$hero);
    this.$fullContent = $('.Hero-content', '.js-Hero.-full');
  }

  init() {
    this.fixes();
    this.slider();
  }

  slider() {
    this.$slider.slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: false,
      infinite: true,
      speed: 800,
      fade: true,
      autoplay: true,
      autoplaySpeed: 5000,
      prevArrow: `
        <div class="Hero-arrowButton Button">
          <span class="Button-icon">
            <svg class="Icon Icon--chevronLeftCircle">
              <use xmlns:xlink="http://www.w3.org/1999/xlink"
              xlink:href="fileadmin/symbol-defs.svg#Icon--chevronLeftCircle">
              </use>
            </svg>
          </span>
        </div>
        `,
      nextArrow: `
        <div class="Hero-arrowButton Hero-arrowButton--next Button">
          <span class="Button-icon">
            <svg class="Icon Icon--chevronRightCircle">
              <use xmlns:xlink="http://www.w3.org/1999/xlink"
              xlink:href="fileadmin/symbol-defs.svg#Icon--chevronRightCircle">
              </use>
            </svg>
          </span>
        </div>
        `,
    });
  }

  fixes() {
    $.each(this.$figure, function () {
      const $this = $(this);
      const hasObjPos = $this.parent().is('[class*="-alignFigure"]');
      fixObjectFitImage($this, hasObjPos, hasObjPos);
    });

    /**
     * Fix to prevent android from changing the height of the hero content item
     * on touch scroll.
     */
    if (ua.isAndroid()) {
      const minHeight = () => {
        $.each(this.$fullContent, function () {
          const $this = $(this);
          $this.css('min-height', '').css('min-height', $this.outerHeight());
        });
      };
      minHeight();
      $(window).on('orientationchange', minHeight);
    }

    /**
     * Fix to set correct viewport height to first hero content items.
     * Tabs etc. in safari are not part of the viewport
     */
    if (ua.isIOS()) {
      const minHeight = () => {
        $.each(this.$fullContent, function () {
          const $this = $(this);
          $this.css('min-height', $(window).height());
        });
      };
      minHeight();
      $(window).on('orientationchange', minHeight);
    }
  }
}

export default Hero;
