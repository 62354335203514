/* ========================================
 * Tabs
 * ======================================== */

import $ from 'jquery';

export default class NavTabs {
  constructor(props = {}) {
    this.tabsClass = props.className || '.js-Nav--tabs'
    this.activeClass = 'is-active'
    this.$tabs = $(this.tabsClass)
    this.linkClass = `${this.tabsClass} .Nav-link`
    this.$link = $(this.linkClass)
    this.ignoreClass = props.ignoreClass || '.js-ignoreTab'
  }

  _removeOtherTargets($exclude) {
    const _this = this
    $(this.linkClass).not(this.ignoreClass).each(function () {
      const $target = $($(this).attr('href') || $(this).attr('data-target'))
      $target.not($exclude).removeClass(_this.activeClass)
    })
  }

  scrollTo (element, to, duration) {
    var start = element.scrollLeft,
        change = to - start,
        currentTime = 0,
        increment = 20

    var easeInOutQuad = function(t, b, c, d) {
      t /= d/2
      if (t < 1) return c/2*t*t + b
      t--
      return -c/2 * (t*(t-2) - 1) + b
    }
    var animateScroll = function() {
      currentTime += increment
      var val = easeInOutQuad(currentTime, start, change, duration)
      element.scrollLeft = val
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    }
  
    animateScroll()
  }


  activateTab({
    current,
    target,
  } = {}) {
    const $currentTab = $(current).parent()
    const $siblingTabs = $currentTab.siblings()
    const $target = $(target)

    $siblingTabs.removeClass(this.activeClass)
    $currentTab.addClass(this.activeClass)

    $target.addClass(this.activeClass)
    this._removeOtherTargets($target)

    var $scrollElement = $currentTab.parent()[0]
    var $scrollPosition = $currentTab[0].offsetLeft - 40
    $scrollPosition = ($scrollPosition > 0) ? $scrollPosition : 0
    this.scrollTo($scrollElement, $scrollPosition, 600)
  }

  _tabs() {
    const _this = this

    $(document).on('click', this.linkClass, function (e) {
      e.preventDefault()
      const $this = $(this)

      const target = $this.attr('href') || $this.attr('data-target');

      _this.activateTab({
        current: $this,
        target: target
      })
    })
  }

  init() {
    if (this.$tabs.length) {
      this._tabs()
    }
  }
}
