/* ========================================
 * RangeSlider
 * ======================================== */
class RangeSlider {
  init() {

    this.initRangeSlider();
  }

  initRangeSlider() {
    $(".js-range-slider").ionRangeSlider({
      type: "double",
      skin: "round",
      step: 0.01,
      hide_from_to: true,
      hide_min_max: true,
      prettify: function (num) {
        var n = num.toString();
        n = n.replace(".", ",");
        return n;
      },
      onChange: function (data) {
        var attribute = data.input.data('attribute')
        var inputFrom = $(`[data-attribute="${attribute}"][data-rangevalue="from"]`)
        var inputTo = $(`[data-attribute="${attribute}"][data-rangevalue="to"]`)
        inputFrom.val(data.from.toFixed(2).toString().replace(".", ","))
        inputTo.val(data.to.toFixed(2).toString().replace(".", ","))
      },
      onStart: function (data) {
        var attribute = data.input.data('attribute')
        var inputFrom = $(`[data-attribute="${attribute}"][data-rangevalue="from"]`)
        var inputTo = $(`[data-attribute="${attribute}"][data-rangevalue="to"]`)
        inputFrom.val(data.from.toFixed(2).toString().replace(".", ","))
        inputTo.val(data.to.toFixed(2).toString().replace(".", ","))
        console.log(data)
      }
    });

    $('.js-range-slider-input').on('change keyup', function() {
      var rangeValue = $(this).data('rangevalue')
      var slider = $(`.js-range-slider[data-attribute="${$(this).data('attribute')}"]`)
      var instance = $(slider).data('ionRangeSlider')

      instance.update({[rangeValue]: $(this).val().toString().replace(",", ".")})
    });
  }
}

export default RangeSlider;
