/* ========================================
 * OverlayBlock
 * ======================================== */

import $ from 'jquery';

class OverlayBlock {
  constructor() {
    this.$dahlShopOverlayBlock = $('.js-OverlayBlock--dahlShop');
  }

  init() {
    this.dahlShop();
  }

  dahlShop() {
    $(window).scroll(() => {
      const scroll = $(window).scrollTop();

      if (scroll >= 300) {
        this.$dahlShopOverlayBlock.addClass('is-scrolledDown');
      } else {
        this.$dahlShopOverlayBlock.removeClass('is-scrolledDown');
      }
    });
  }
}

export default OverlayBlock;
